import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

import Container from '../layout/Container';
import SpeakersList from '../components/SpeakersList';
import { ColourLevel } from '../types';

const Wrapper = styled(Container)`
  position: relative;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.sizes.lvl5} ${({ theme }) => theme.sizes.lvl2};

  ${({ theme }) => theme.breakpoint('phoneLarge')`
    padding: ${theme.sizes.lvl5} ${theme.sizes.lvl3};
  `}
  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding: ${theme.sizes.lvl7} ${theme.sizes.lvl4};
  `}
  
  ${({ theme }) => theme.breakpoint('desktop')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl14};
  `}
    ${({ theme }) => theme.breakpoint('medium')`
    padding: ${theme.sizes.lvl10} ${theme.sizes.lvl16};
  `}
`;

const HomepageExpertsSection = () => {
  const { allAirtable } = useStaticQuery(graphql`
    query {
      allAirtable(
        filter: {
          table: { eq: "Session" }
          data: {
            BonusSession: { ne: true }
            Speaker: {
              elemMatch: {
                data: { MainHost: { ne: true }, Host: { ne: true } }
              }
            }
          }
        }
        sort: { fields: data___Speaker___data___ExpertOrder, order: ASC }
      ) {
        edges {
          node {
            data {
              Name
              Content {
                childMarkdownRemark {
                  html
                }
              }
              Speaker {
                id
                data {
                  Name
                  WebsiteUri
                  Avatar {
                    localFiles {
                      childImageSharp {
                        gatsbyImageData(width: 200)
                      }
                    }
                  }
                }
              }
            }
            id
          }
        }
      }
    }
  `);

  const speakers =
    allAirtable?.edges
      ?.map((edge) => edge?.node)
      .map(({ data }) => ({
        id: data.Speaker[0].id,
        name: data.Speaker[0].data.Name,
        tagline: data.Name,
        image: data.Speaker[0].data.Avatar.localFiles[0],
        websiteUri: data.Speaker[0].data.WebsiteUri,
        slug: data.Slug,
        content: data.Content,
        category: data.Category,
      })) || [];

  return (
    <Wrapper id="conferenceexperts">
      <h1 className="h1">Conference Experts</h1>
      <SpeakersList
        speakers={speakers}
        colourLevel={ColourLevel.TERTIARY}
        hideBuyButton
      />
    </Wrapper>
  );
};

export default HomepageExpertsSection;
