import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import IconList, { PaddingSize } from '../components/IconList';
import Container from '../layout/Container';

const Inner = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colours.white};
`;

const ListContainer = styled.ul`
  li {
    margin-bottom: ${({ theme }) => theme.sizes.lvl1};
    font-size ${({ theme }) => theme.font.sizes.md};
    
    ${({ theme }) => theme.breakpoint('desktop')`
        font-size ${({ theme }) => theme.font.sizes.lg};`}
  }

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    li {
      margin-bottom: ${({ theme }) => theme.sizes.lvl2};
    }
  `}
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  background-attachment: fixed;
`;

interface BackgroundSectionProps {
  data: any;
  className?: string;
  children: ReactNode;
}

const BackgroundSection = ({
  data,
  className,
  children,
}: BackgroundSectionProps) => {
  const backgroundFluidImageStack = [
    data.desktop.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3))`,
  ].reverse();

  return (
    <StyledBackgroundImage
      Tag="section"
      className={className}
      fluid={backgroundFluidImageStack}
      backgroundColor={`#040e18`}
    >
      {children}
    </StyledBackgroundImage>
  );
};

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;

  padding-top: ${({ theme }) => theme.sizes.lvl10};

  ${({ theme }) => theme.breakpoint('tabletPortrait')`
    padding-top: 0;
  `}
`;

const ConferenceContentSection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "conference_content_bg.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        puzzle: file(relativePath: { eq: "puzzle_colour.png" }) {
          childImageSharp {
            fluid(maxWidth: 150) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return (
    <StyledBackgroundSection data={data}>
      <Inner>
        <h1 className="h2">
          Our
          <span> Experts </span>
          will cover:
        </h1>

        <ListContainer>
          <IconList
            paddingSize={PaddingSize.XS}
            items={[
              { content: 'The role of gut issues in fatigue', included: true },
              {
                content: 'How toxins and chemicals can zap energy',
                included: true,
              },
              {
                content:
                  'How to identify if autoimmunity and thyroid issues may be playing a part',
                included: true,
              },
              {
                content:
                  'How maladaptive stress response and trauma create fatigue',
                included: true,
              },
              {
                content:
                  'How to identify if mold or Lyme may be an underlying cause of fatigue',
                included: true,
              },
              {
                content:
                  'How to identify if your adrenals or hormones may be playing a part',
                included: true,
              },
              {
                content: 'And much more...',
                included: true,
              },
            ]}
          />
        </ListContainer>
      </Inner>
    </StyledBackgroundSection>
  );
};

export default ConferenceContentSection;
