import React from 'react';
import styled from 'styled-components';

import Container from '../layout/Container';

const Background = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colours.primary};
`;

const Inner = styled(Container)`
  color: ${({ theme }) => theme.colours.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const StyledTitle = styled.h1`
  ${({ theme }) => theme.breakpoint('tabletLandscape')`
    br {
      display: none;
    }
  `}
`;

const WhatIsSuperConferenceSection = () => {
  return (
    <Background>
      <Inner>
        <StyledTitle className="h2">
          What makes this a <br />
          <span>Super conference?</span>
        </StyledTitle>
        <p>
          We are not only bringing together{' '}
          <strong className="strong">
            in-depth interviews with 40+ of the world's top experts on fatigue
          </strong>
          . We are also offering a ton of{' '}
          <strong className="strong">extra resources</strong>, including
          inspirational recovery stories, in-depth case studies breaking down
          real life recovery stories from the practitioner perspective, and
          daily yoga and meditation sessions.
        </p>
      </Inner>
    </Background>
  );
};

export default WhatIsSuperConferenceSection;
